import store from '@/state/store'

export default [{
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/users/login'),
        meta: {
            mainCapp: [],
            cappList: [],
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'اصلی'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            mainCapp: [],
            cappList: [],
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.removeItem('auth');
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? {
                    name: 'اصلی'
                } : {
                    ...routeFrom
                })
            },
        },
    },
    {
        path: '/',
        name: 'اصلی',
        meta: {
            authRequired: true,
            mainCapp: ["AllList"],
            cappList: []
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/dash/chart',
        name: 'نمودار ها',
        meta: {
            authRequired: true,
            mainCapp: ["AllChart", "GetPositions"],
            cappList: []
        },
        component: () => import('../views/pages/dashboard/all-chart')
    },
    // {
    //     path: '/analysis',
    //     name: 'تحلیلات',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/analysis/all')
    // },
    // {
    //     path: '/analysis/milk',
    //     name: 'شیری',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/analysis/milk')
    // },
    // {
    //     path: '/analysis/dry',
    //     name: 'خشک',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/analysis/dry')
    // },
    // {
    //     path: '/analysis/pregnant',
    //     name: 'تلیسه آبستن',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/analysis/pregnant')
    // },
    // {
    //     path: '/analysis/nonpregnant',
    //     name: 'تلیسه غیر آبستن',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/analysis/nonpregnant')
    // },
    // {
    //     path: '/analysis/claf',
    //     name: 'گوساله زیر یکسال',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/analysis/claf')
    // },
    {
        path: '/warning',
        name: 'هشدار ها',
        meta: {
            authRequired: true,
            mainCapp: ["Warning"],
            cappList: []
        },
        component: () => import('../views/pages/utility/warning')
    },
    {
        path: '/users',
        name: 'کاربران',
        meta: {
            authRequired: true,
            mainCapp: ["GetUsers", "GetRoles"],
            cappList: ["DeleteUser", "ChangeStatus", "AllChangeStatus", "AddUser"]
        },
        component: () => import('../views/pages/users/index')
    },
    {
        path: '/users/role',
        name: 'نقش ها',
        meta: {
            authRequired: true,
            mainCapp: ["GetRoles", "GetAccess"],
            cappList: ["UpdateRole"]
        },
        component: () => import('../views/pages/users/role')
    },
    {
        path: '/users/:id',
        name: ' ویرایش کاربر',
        meta: {
            authRequired: true,
            mainCapp: ["GetUser", "GetRoles"],
            cappList: ["EditUser", "ChangePass"]
        },
        component: () => import('../views/pages/users/edit')
    },
    {
        path: '/cattel',
        name: 'دام ها',
        meta: {
            authRequired: true,
            mainCapp: ["GetCattels", "GetPositions"],
            cappList: ["AddCow", "AddInoc", "AddBirth", "AddVisit", "AddDry", "AddMilkrecord", "AddBodys", "AddMotion", "AddIllnes", "UploadCsv", "OneNCsv", "UploadCsvItem", "OneNCsvItem", "DeleteCow", "AllPosCow", "AllDeleteCow"]
        },
        component: () => import('../views/pages/cattel/index')
    },
    {
        path: '/cattel/show/:id',
        name: ' نمایش دام',
        meta: {
            authRequired: true,
            mainCapp: ["GetCow", "GetEvents"],
            cappList: []
        },
        component: () => import('../views/pages/cattel/show')
    },
    {
        path: '/cattel/:id',
        name: ' ویرایش دام',
        meta: {
            authRequired: true,
            mainCapp: ["GetCow", "GetPositions", "GetInoculated", "GetBirth", "GetVisit", "GetDry", "GetMilkrecord", "GetBodys", "GetMotion", "GetIllnes"],
            cappList: ["EditCow", "EditInoc", "DeleteInoc", "EditBirth", "DeleteBirth", "EditVisit", "DeleteVisit", "EditDry", "DeleteDry", "EditMilkrecord", "DeleteMilkrecord", "EditBodys", "DeleteBodys", "EditMotion", "DeleteMotion", "EditIllnes", "DeleteIllnes"]
        },
        component: () => import('../views/pages/cattel/edit')
    },
    {
        path: '/positions',
        name: 'جایگاه ها',
        meta: {
            authRequired: true,
            mainCapp: ["GetPositions"],
            cappList: ["AddPos", "DeletePos", "GetPos", "EditPos"]
        },
        component: () => import('../views/pages/positions/index')
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        meta: {
            authRequired: true,
            mainCapp: [],
            cappList: []
        },
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/coming-soon',
        name: 'Coming-soon',
        meta: {
            authRequired: true,
            mainCapp: [],
            cappList: []
        },
        component: () => import('../views/pages/utility/coming-soon')
    },
    {
        path: '/error-500',
        name: 'Error-500',
        meta: {
            authRequired: true,
            mainCapp: [],
            cappList: []
        },
        component: () => import('../views/pages/utility/error-500')
    },
    {
        path: '/error-403',
        name: 'Error-403',
        meta: {
            authRequired: true,
            mainCapp: [],
            cappList: []
        },
        component: () => import('../views/pages/utility/error-403')
    },
    {
        path: '/*',
        name: 'Error-404',
        meta: {
            authRequired: true,
            mainCapp: [],
            cappList: []
        },
        component: () => import('../views/pages/utility/error-404')
    },
]