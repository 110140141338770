import { authService } from '../../helpers/auth.service';

const user = localStorage.getItem('auth');
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { userphone, password }) {
        commit('loginRequest', { userphone });
        authService.login(userphone, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    location.href = "/"
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
};