import Router from '../router'

export const authService = {
    login,
};

function login(userphone, password) {

    const requestOptions = {
        method: 'POST',   
        body: JSON.stringify({
            "phone": userphone,
            "password": password
        })
    };

    return fetch(process.env.VUE_APP_BACKEND_URL+`/login/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.token) {
                delete user.code;
                delete user.message;
                delete user.success;
                localStorage.setItem('auth', JSON.stringify(user));
                if (localStorage.getItem("theme") == null) {                    
                    localStorage.setItem('theme', JSON.stringify({
                        layoutType: 'horizontal',
                        layoutWidth: 'fluid',
                        leftSidebarType: 'dark',
                        topbar: 'dark',
                        loader: true
                    }));
                }
            }
            return user;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('auth');
                location.reload();
            }
            if (response.status === 500 || response.status === 503) {
                Router.push({
                    path: "/error-500",
                    redirect: {
                        name: "Error-500"
                    },
                });
            }
        }
        if (response.status === 410 || response.status === 403) {
            const error = data;
            return Promise.reject(error);
        }
        return data;
    });
}